<template>
  <div id="sysRoleEdit">
    <Modal
      :mask-closable="failed"
      @on-cancel="close"
      class="sys-role-edit-modal"
      v-model="flag"
      width="400"
    >
      <p slot="header">{{ formd.Id ? "编辑角色" : "新增角色" }}</p>
      <Spin class="bk-spin-loading" fix v-if="loading">
        <Icon class="bk-spin-icon-loading" type="ios-loading"></Icon>
        <div class="bk-spin-loading-text">Loading</div>
      </Spin>
      <Spin class="bk-spin-failed" fix v-if="failed">
        <Icon @click="req" class="bk-spin-icon-failed" type="md-refresh" />
        <div @click="req" class="bk-spin-failed-text">Reload</div>
      </Spin>
      <Form :label-width="100" :model="formd" :rules="rules" @submit.native.prevent ref="form">
        <FormItem label="角色名称：" prop="RoleName" required>
          <Input placeholder="请输入名称" v-if="ABLE" v-model="formd.RoleName" />
          <div v-else>{{formd.RoleName}}</div>
        </FormItem>
        <FormItem label="备注：">
          <Input placeholder="请输入备注" type="textarea" v-if="ABLE" v-model="formd.Remark" />
          <div v-else>{{formd.Remarak}}</div>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="close" type="text">关闭</Button>
        <Button :loading="posting" @click="ok" type="primary" v-if="ABLE">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ABLE: false,
      flag: false,
      loading: false,
      failed: false,
      posting: false,
      formd: {},
      rules: {
        RoleName: [{ required: true, message: " " }],
      },
    };
  },
  methods: {
    open(id) {
      this.flag = true;
      if (id) {
        this.ABLE = this.$able.map.SysRoleUpd;
        this.formd.Id = id;
        this.req();
      } else {
        this.ABLE = this.$able.map.SysRoleAdd;
      }
    },
    close() {
      this.ABLE = false;
      this.flag = false;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.formd = {};
      this.$refs.form.resetFields();
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    // ==========
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BeikeRole/GetById", { params: { Id: this.formd.Id } })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.failed = false;
            this.formd = res.data;
          } else {
            this.failed = true;
            if (err.errormsg) {
              this.$Message.error(err.errormsg);
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    postData() {
      this.posting = true;
      let param = this.formd;
      this.$axios
        .post(param.Id ? "/Api/BeikeRole/Update" : "/Api/BeikeRole/Add", param)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success(param.Id ? "编辑成功" : "新增成功");
            this.close();
            param.Id ? this.$emit("on-upd") : this.$emit("on-add");
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>

