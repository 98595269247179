<template>
  <div class="bk-page" id="sysRoleLst">
    <div class="bk-page-scroll">
      <div class="bk-page-lst">
        <Form @submit.native.prevent class="bk-page-search" inline>
          <FormItem class="btns">
            <Button @click="toAdd" ghost type="primary" v-if="$able.map.SysRoleAdd">
              <Icon type="md-add" />新增
            </Button>
          </FormItem>
        </Form>
        <Table :columns="tableCols" :data="tableData" :loading="tableLoading"></Table>
        <Page
          :current="pageIndex"
          :page-size="pageSize"
          :total="recordCount"
          @on-change="(index) => onChangePage(index, 0)"
          @on-page-size-change="(size) => onChangePage(1, size)"
          show-elevator
          show-sizer
          show-total
          style="flex-shrink: 0"
          transfer
        />
      </div>
    </div>
    <edit @on-add="onChangePage(1)" @on-upd="reqTable" ref="edit" />
    <module ref="module" />
  </div>
</template>
<script>
import edit from "./edit";
import module from "./module";
export default {
  components: { edit, module },
  data() {
    return {
      searchView: {},
      searchData: {},
      // ——————————
      tableLoading: false,
      tableCols: [
        { title: "角色名称", key: "RoleName" },
        { title: "创建时间", key: "CreateTime" },
        { title: "更新时间", key: "UpdateTime" },
        { title: "备注", key: "Remark" },
        {
          title: "操作",
          width: 140,
          align: "right",
          render: (h, params) => {
            let a = [];
            if (this.$able.map.SysRoleSetPriv) {
              a.push(
                h("span", {
                  style: { cursor: "pointer", color: "#2d8cf0" },
                  on: {
                    click: () => {
                      this.$refs.module.open(params.row.Id, params.row.RoleName);
                    },
                  },
                }, "权限")
              );
              a.push(h("Divider", { props: { type: "vertical" } }));
            }
            a.push(
              h("Icon", {
                props: { type: this.$able.map.SysRoleUpd ? "md-create" : "md-open", size: 18 },
                attrs: { title: this.$able.map.SysRoleUpd ? "编辑" : "详情" },
                style: { cursor: "pointer", color: "#2d8cf0" },
                on: {
                  click: () => {
                    this.$refs.edit.open(params.row.Id);
                  },
                },
              })
            );
            a.push(h("Divider", { props: { type: "vertical" } }));
            if (this.$able.map.SysRoleDel) {
              a.push(
                h("Icon", {
                  props: { type: "md-close", size: 18 },
                  attrs: { title: "删除" },
                  style: { cursor: "pointer", color: "#ed4014" },
                  on: {
                    click: () => {
                      this.toDel(params.row);
                    },
                  },
                })
              );
            }
            return a;
          },
        },
      ],
      tableData: [],
      // ——————————
      recordCount: 0,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  activated: function () {
    this.$root.$off("on-refresh");
    this.$root.$on("on-refresh", () => {
      this.initSearch();
    });
    if (this.$route.params.re == 1) {
      this.initSearch();
    }
  },
  methods: {
    initSearch() {
      this.searchView = {};
      this.pageIndex = 1;
      this.onSearch();
    },
    onSearch() {
      let data = this.$util.copy(this.searchView);
      this.searchData = data;
      this.pageIndex = 1;
      this.reqTable();
    },
    reqTable() {
      if (this.$able.map.SysRoleQry == undefined) {
        this.$Message.error("无权限配置")
        return
      }
      let params = Object.assign(
        {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        },
        this.searchData
      );
      this.tableLoading = true;
      this.$axios
        .get("/Api/BeikeRole/QueryPage", { params: params })
        .then((res) => {
          this.tableLoading = false;
          if (res.result) {
            this.recordCount = res.data.RecordCount;
            this.tableData = res.data.DataSet || [];
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pageIndex = index;
      }
      if (size > 0) {
        this.pageSize = size;
      }
      this.reqTable();
    },
    // ==========
    toAdd() {
      this.$refs.edit.open();
    },
    toDel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除：" + this.$util.color(row.RoleName),
        onOk: () => {
          this.doDel(row.Id);
        },
      });
    },
    doDel(id) {
      this.$axios
        .post("/Api/BeikeRole/Delete", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("删除成功");
            this.reqTable();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>
