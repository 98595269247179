<template>
  <div id="ModuleCode">
    <Drawer
      :title="`${RoleName}-设置权限`"
      @on-close="close"
      class="bk-drawer module-code-drawer"
      v-model="flag"
      width="300"
    >
      <div class="bk-drawer-body">
        <Tree :data="tree" :empty-text="emptyText" multiple ref="tree" show-checkbox></Tree>
      </div>
      <div class="bk-drawer-footer">
        <Button :loading="posting" @click="ok" type="primary">提交</Button>
      </div>
    </Drawer>
  </div>
</template>
<script>
export default {
  name: "ModuleCode",
  data() {
    return {
      RoleId: "",
      RoleName: "",
      flag: false,
      posting: false,
      tree: [],
      emptyText: "加载中...",
    };
  },
  methods: {
    open(Id, RoleName) {
      this.flag = true;
      this.RoleId = Id;
      this.RoleName = RoleName;
      this.getRoleCode();
    },
    close() {
      this.flag = false;
      this.RoleId = "";
      this.RoleName = "";
      this.tree = [];
    },
    ok() {
      let data = this.$refs.tree.getCheckedNodes();
      let param = {
        RoleId: this.RoleId,
        RoleModules: data.map((v) => {
          return {
            RoleId: this.RoleId,
            ModuleCode: v.code,
          };
        }),
      };
      this.posting = true;
      this.$axios
        .post("/Api/BeikeRoleModule/AddEx", param)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("提交成功");
            this.close();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.posting = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    // ========
    getAllCode(ModuleCodeObj) {
      this.emptyText = "加载中...";
      this.$axios
        .get("/Api/BeikePrivModule/QueryAll")
        .then((res) => {
          if (res.result) {
            let data = res.data;
            let tree = [];
            let subObj = {}; // parentId索引的子级数组
            if (data.length == 0) {
              this.emptyText = "暂无数据";
            }
            for (let i in data) {
              if (data[i].ParentId == 0) {
                // 根级
                tree.push({
                  Id: data[i].Id,
                  title: data[i].ModuleName,
                  code: data[i].ModuleCode,
                  children: [],
                  expand: true,
                  checked: false,
                });
              } else {
                if (subObj[data[i].ParentId]) {
                  // 已存在数组
                  subObj[data[i].ParentId].push({
                    Id: data[i].Id,
                    title: data[i].ModuleName,
                    code: data[i].ModuleCode,
                    children: [],
                    expand: true,
                    checked: false,
                  });
                } else {
                  // 不存在数组
                  subObj[data[i].ParentId] = [
                    {
                      Id: data[i].Id,
                      title: data[i].ModuleName,
                      code: data[i].ModuleCode,
                      children: [],
                      expand: true,
                      checked: false,
                    },
                  ];
                }
              } 
            }
            // 嵌入第二、三层
            for (let i in tree) {
              if (subObj[tree[i].Id]) {
                tree[i].children = subObj[tree[i].Id];
                let len1 = tree[i].children.length;
                for (let j in tree[i].children) {
                  if (subObj[tree[i].children[j].Id]) {
                    tree[i].children[j].children = subObj[tree[i].children[j].Id];
                    let len2 = tree[i].children[j].children.length;
                  }
                }
              }
            }
            // 为避免新增权限码，而父级节点保持之前的全选状态，故从第三层逆推选中状态
            for (let i in tree) {
              let b1 = tree[i].children.length
              let b2 = 0
              for (let j in tree[i].children) {
                let c1 = tree[i].children[j].children.length
                let c2 = 0
                for (let k in tree[i].children[j].children) {
                  if (!!ModuleCodeObj[tree[i].children[j].children[k].code]) {
                    tree[i].children[j].children[k].checked = true
                    c2++
                  }
                }
                if (c1==c2) {
                  tree[i].children[j].checked = true
                  b2++
                }
              }
              if (b1==b2) {
                tree[i].checked = true
              }
            }
            this.tree = tree;
          } else {
            this.emptyText = "请求出错";
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          console.error(err);
          this.emptyText = "网络错误";
          this.$Message.error("网络错误");
        });
    },
    getRoleCode() {
      this.$axios
        .get("/Api/BeikeRoleModule/SelectByRoleID/" + this.RoleId)
        .then((res) => {
          if (res.result) {
            let ModuleCodeObj = {};
            res.data.OpModules.map((v) => {
              ModuleCodeObj[v.ModuleCode] = 1;
            });
            try {
              this.getAllCode(ModuleCodeObj);
            } catch (err) {
              console.log(err);
            }
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          console.error(err);
          this.$Message.error("网络错误");
        });
    },
  },
};
</script>
<style lang="less">
.module-code-drawer {
  .ivu-tree {
    margin: 20px;
  }
}
</style>
